import React from "react";
import { Box } from "@mui/material";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useTheme } from "@mui/material/styles";
import FullWidthContent from "../components/FullWidthContent";

function Projects() {
  const theme = useTheme();

  return (
    <>
      <Navbar showLanguageSelector={true} showThemeSwitch={false} />
      <Box
        className="content"
        sx={{
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          flex: 1,
          minHeight: "calc(100vh - 64px - 64px)",
          overflowX: "hidden",
        }}
      >
        <FullWidthContent
          title="Matchups.net"
          content="matchups_description"
          contentAlignment="center"
          ctaText="view_site"
          ctaLink="https://matchups.net"
          ctaTarget="_blank"
          ctaRel="noopener noreferrer"
          backgroundImage="/images/matchups.png"
          titleColor="#785fe6"
          contentColor="#785fe6"
          focusWordKeys={["matchups"]}
          focusColor="#785fe6"
        />

        <FullWidthContent
          title="Gabu Template"
          content="template_description"
          contentAlignment="center"
          ctaText="view_site"
          ctaLink="https://gabutemplate.com"
          ctaTarget="_blank"
          ctaRel="noopener noreferrer"
          backgroundImage="/images/template.png"
          titleColor="#785fe6"
          contentColor="#785fe6"
          focusWordKeys={["template"]}
          focusColor="#785fe6"
        />

        <FullWidthContent
          title="Kremaldy Games"
          content="kremaldy_description"
          contentAlignment="center"
          ctaText="view_repository"
          ctaLink="https://github.com/GaburaisuVGC/kremaldygames"
          ctaTarget="_blank"
          ctaRel="noopener noreferrer"
          backgroundImage="/images/kremaldy.png"
          titleColor="#785fe6"
          contentColor="#785fe6"
          focusWordKeys={["kremaldy"]}
          focusColor="#785fe6"
        />

        <FullWidthContent
          title="VGC Hub"
          content="vgchub_description"
          contentAlignment="center"
          ctaText="view_repository"
          ctaLink="https://github.com/GaburaisuVGC/vgc-hub"
          ctaTarget="_blank"
          ctaRel="noopener noreferrer"
          backgroundImage="/images/vgchub.png"
          titleColor="#785fe6"
          contentColor="#785fe6"
          focusWordKeys={["vgc"]}
          focusColor="#785fe6"
        />

        {/* <FullWidthContent
            title="Nokia 0024"
            content="Un projet étudiant d'émulateur de Nokia 3310 pour les smartphones modernes."
            contentAlignment="center"
            ctaText="Voir le repository"
            ctaLink="https://gitlab.mt.glassworks.tech/a_goarnisson/nokia0024"
            backgroundImage="/images/nokia0024.png"
            titleColor="#785fe6"
            contentColor="#785fe6"
            focusWordKeys={['nokia']}
            focusColor="#785fe6"
        /> */}

        {/* Dernier FullWidthContent */}
        <FullWidthContent
          title="github_cta_title_2"
          content="github_cta_description"
          contentAlignment="center"
          ctaText="visit_github"
          ctaLink="https://github.com/GaburaisuVGC"
          ctaTarget="_blank"
          ctaRel="noopener noreferrer"
          backgroundImage="/images/blurred_image.png"
          titleColor="white"
          contentColor="white"
          focusWordKeys={["github", "discover"]}
          focusColor="#785fe6"
        />
      </Box>
      <Footer />
    </>
  );
}

export default Projects;

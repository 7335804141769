import React from 'react';
import { Container, Box, Grid2, Avatar, Typography } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useTheme } from '@mui/material/styles';
import CustomContent from '../components/CustomContent';
import FullWidthContent from '../components/FullWidthContent';
import { useTranslation } from "react-i18next";
import jsIcon from '../assets/icons/js.png';
import reactIcon from '../assets/icons/react.png';
import pythonIcon from '../assets/icons/python.png';
import htmlIcon from '../assets/icons/html.png';
import cssIcon from '../assets/icons/css.png';
import gcpIcon from '../assets/icons/gcp.png';
import firebaseIcon from '../assets/icons/firebase.png';

function Home() {
  const theme = useTheme();
  const { t } = useTranslation();

  const skills = [
    {
      name: 'JavaScript',
      icon: jsIcon,
    },
    {
      name: 'React',
      icon: reactIcon,
    },
    {
      name: 'Python',
      icon: pythonIcon,
    },
    {
      name: 'HTML',
      icon: htmlIcon,
    },
    {
      name: 'CSS',
      icon: cssIcon,
    },
    {
      name: 'GCP',
      icon: gcpIcon,
    },
    {
      name: 'Firebase',
      icon: firebaseIcon,
    },
  ];

  return (
    <>
      <Navbar showLanguageSelector={true} showThemeSwitch={false} />
      <Box
        className="content"
        sx={{
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          flex: 1,
          minHeight: 'calc(100vh - 64px - 64px)',
          overflowX: 'hidden',
        }}
      >
        {/* Premier FullWidthContent */}
        <FullWidthContent
          title="home_title"
          content="home_description"
          focusWordKeys={['template', 'development']}
          focusColor="#785fe6"
          contentAlignment="center"
          ctaText="learn_more"
          ctaLink="/projects"
          marginTopNonMobile={20}
        />

        {/* Fonctionnalités */}
        <Box my={24}>
          <Container maxWidth="md">
            {/* Sous titre : expériences professionnelles */}
            <h2
              style={{
                textAlign: 'center',
                fontSize: '2rem',
                marginBottom: '2rem',
                paddingTop: '4rem',
              }}
            >{t('experiences')}</h2>
            {/* Boutons CTA */}
            <Box my={12}>
              <CustomContent
                title="hardis_title"
                content="hardis_description"
                image="/images/hardis.png"
                imagePosition="left"
                animationDirection="left"
                ctaText="view_cv"
                ctaLink="/CV.pdf"
                ctaPosition="right"
                ctaTarget="_blank"
                ctaRel="noopener noreferrer"
                listItems={['hardis_one', 'hardis_two', 'hardis_three']}
                listType="bullet"
                ctaStyles={{}}
                contentAlignment="center"
              />
            </Box>

            {/* Images */}
            <Box my={12}>
              <CustomContent
                title="sesame_title"
                content="sesame_description"
                animationDirection="right"
                ctaText="view_cv"
                ctaLink="/CV.pdf"
                ctaPosition="center"
                ctaTarget="_blank"
                ctaRel="noopener noreferrer"
                listItems={['sesame_one', 'sesame_two', 'sesame_three']}
                listType="bullet"
                ctaStyles={{}}
                contentAlignment="center"
              />
            </Box>

            <h2
              style={{
                textAlign: 'center',
                fontSize: '2rem',
                marginBottom: '2rem',
                paddingTop: '2rem',
              }}
            >{t('certifications')}</h2>

            {/* Listes à puces */}
            <Box my={12}>
              <CustomContent
                title="hetic_title"
                content="hetic_description"
                image="/images/hetic.jpg"
                imagePosition="right"
                animationDirection="right"
                ctaStyles={{}}
                contentAlignment="center"
              />
            </Box>

            {/* Cases à cocher */}
            <Box my={12}>
              <CustomContent
                title="gcp_title"
                image="/images/gcp.png"
                imagePosition="left"
                animationDirection="left"
                ctaStyles={{}}
                contentAlignment="center"
              />
            </Box>

            {/* Compétences */}
            <Box my={12}>
            {/* créer une grid de 8 icones */}
            <h2
                style={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  marginBottom: '2rem',
                  paddingTop: '2rem',
                }}
              >
                {t('skills')}
              </h2>
              <Container maxWidth="md">
                <Grid2 container spacing={4} justifyContent="center">
                  {skills.map((skill) => (
                    <Grid2 item xs={6} sm={3} key={skill.name}>
                      <Box textAlign="center">
                        <Avatar
                          src={skill.icon}
                          alt={skill.name}
                          sx={{ width: 80, height: 80, margin: '0 auto' }}
                          className='icon'
                        />
                        <Typography variant="subtitle1" sx={{ mt: 1 }}>
                          {skill.name}
                        </Typography>
                      </Box>
                    </Grid2>
                  ))}
                </Grid2>
              </Container>

            </Box>

            {/* Toutes les fonctionnalités */}
            <Box my={16}>
              <CustomContent
                title="all_features_title"
                content="all_features_description"
                ctaText="view_all_features"
                ctaLink="/projects"
                ctaPosition="center"
                ctaStyles={{}}
                spacing={4}
                borderRadius={4}
                boxShadow={3}
                animationDirection="up"
                contentAlignment="center"
              />
            </Box>
          </Container>
        </Box>

        {/* Dernier FullWidthContent */}
        <FullWidthContent
          title="github_cta_title"
          content="github_cta_description"
          contentAlignment="center"
          ctaText="visit_github"
          ctaLink="https://github.com/GaburaisuVGC"
          ctaTarget="_blank"
          ctaRel="noopener noreferrer"
          backgroundImage="/images/blurred_image.png"
          titleColor="white"
          contentColor="white"
          focusWordKeys={['github', 'discover']}
          focusColor="#785fe6"
        />
      </Box>
      <Footer />
    </>
  );
}

export default Home;
